import { Avatar } from "./Avatar";
import { useTranslation } from "react-i18next";

type GamePlayerTeamJoinedType = {
    players: {
        displayPicture?: string;
    }[];
    reservedPlayers: number;
    maxPlayers: number;
};

export const GamePlayerTeamJoined = ({
    players,
    reservedPlayers,
    maxPlayers,
}: GamePlayerTeamJoinedType): JSX.Element => {
    const { t } = useTranslation("components/GamePlayerTeamJoined");
    const maxDisplay = 6;
    const displayPlayer = players.slice(0, maxDisplay);
    while (displayPlayer.length < maxPlayers - 1) {
        displayPlayer.push({});
        if (displayPlayer.length >= maxDisplay) break;
    }

    const text = t("playersJoined", {
        count: players.length + reservedPlayers,
        max: maxPlayers,
    });
    return (
        <div className="flex items-center py-0.5">
            <div className="flex items-center pl-1">
                {displayPlayer.map((p, i) => (
                    <Avatar
                        key={i}
                        src={p.displayPicture}
                        index={i}
                        size="24.5"
                        className="-mx-1"
                    />
                ))}
                {players.length < maxPlayers && (
                    <Avatar
                        index={displayPlayer.length}
                        text={maxPlayers}
                        size="24.5"
                        className="-mx-1"
                    />
                )}
            </div>
            <span className="pl-3 text-tiny text-blue-grey-400">{text}</span>
        </div>
    );
};
